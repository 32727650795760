import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .url-content {
    display: grid;
    grid-template-columns: 40.7% 57%;
    width: 100%;
    gap: 1.25rem;
  }
`;
